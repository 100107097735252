<template lang="pug">
v-container.pa-0(fluid  ) 
    component( v-for='{componente, objeto}, i of secciones' :key='i' :is='componente' :objeto='objeto')
</template>
<script>

export default {
  computed: {
    secciones(){
      return [
      {componente: 'encabezado', objeto: null},
     
    ]}
  },
  components: {
    encabezado: ()=>import('./encabezado'),
   

  }
}
</script>

